import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import { apiRequest, getExceptionDetails } from "../utils";
import * as routes from "../routes";
import * as icons from "../media/icons";

function CreatePasteBin() {
	const { pin } = useParams<{ pin?: string }>();
	const inputRef = useRef<HTMLTextAreaElement | null>(null);
	const [busy, setBusy] = useState(false);
	const [pasteBinText, setPasteBinText] = useState('');
	const [result, setResult] = useState(null as string | null);

	useEffect(() => {
		// set focus to textbox
		inputRef.current?.focus();
	}, []);

	useEffect(() => {
		if (pin == null)
			// noop
			return;
		setBusy(true);
		(async () => {
			try {
				const { pasteBinText } = await apiRequest<routes.api.tempLinks.GetPasteBinResult>({
					method: 'GET',
					url: routes.api.tempLinks.getPasteBin(pin),
					exceptionMessage: `Error while fetchin content`,
				});
				setPasteBinText(pasteBinText);
			} catch (err) {
				setResult(await getExceptionDetails(err));
			} finally {
				setBusy(false)
			}
		})();
	}, [pin]);

	async function createPin() {
		if (pasteBinText.trim() === '')
			return;

		try {
			setBusy(true);

			const body: routes.api.tempLinks.CreatePasteBinRequest = { pasteBinText };
			const result = await apiRequest<routes.api.tempLinks.CreateLinkResult>({
				method: 'POST',
				url: routes.api.tempLinks.pasteBin(),
				body,
				exceptionMessage: `Error while creating PIN number`,
			});
			setResult(JSON.stringify(result, null, '    '));
		} finally {
			setBusy(false);
		}
	}

	if (busy)
		return <icons.LoadingSpinnerPage />;

	return <>
		<InputGroup className="my-3 px-5">
			<InputGroup.Text>Text</InputGroup.Text>
			<Form.Control as="textarea"
				ref={inputRef}
				style={{ height: '10em' }}
				value={pasteBinText}
				onChange={(e) => setPasteBinText(e.target.value)}
			/>
			<Button className="input-group-text"
				onClick={() => createPin()}
			>
				Create
			</Button>
		</InputGroup>

		{(result !== null) &&
			<pre>{result}</pre>
		}
	</>
}

export default CreatePasteBin;
